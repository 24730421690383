<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >
        <base-material-tabs
          v-model="tab"

          color="warning"
          icons-and-text
        >
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
          >
            {{ tab.text }}
            <v-icon v-text="tab.icon" />
          </v-tab>

          <v-tabs-items
            v-model="tab"
            class="pt-12 transparent"
          >

            <v-tab-item>
              <base-material-card
                  icon="mdi-clipboard-text"
                  title="List Data Laporan Pekerjaan"
                  class="px-5 py-3"
                >
                  <v-row>
                    <v-col>
                      <v-dialog
                          ref="SelMonth"
                          v-model="menuSelMonth"
                          :return-value.sync="selectedMonth"
                          persistent
                          width="290px"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="selectedMonth"
                            label="Pilih Bulan/Tahun"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="selectedMonth" type="month" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuSelMonth = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.SelMonth.save(selectedMonth)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col>
                    <v-btn color="blue" @click="feedingTable()">Show Data</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="red" v-on="on" @click="showAddForm()">
                              <v-icon>mdi-plus</v-icon>Buat Laporan
                          </v-btn>
                        </template>
                        <span>Klik disini untuk membuat Laporan Pekerjaan Baru.</span>
                      </v-tooltip>
                      
                    </v-col>
                  </v-row>

                  <v-data-table
                      :headers="headers"
                      :items="listLaporans"
                      :items-per-page="15"
                      class="elevation-1"
                      @selected="feedingTable()"
                    >
                    <template v-slot:item.aksi="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="warning" v-on="on" fab x-small :to="'/repwork/upwfh/'+item.id" >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah</span>
                      </v-tooltip>
                      &nbsp;
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="item.linkfile == null" v-on="on" color="green" fab x-small @click="goFileLink(item.linkfile)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>Lihat File Laporan</span>
                      </v-tooltip>
                      
                      &nbsp;

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="red" v-on="on" fab x-small @click="delLaporan(item.id)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Hapus Laporan</span>
                      </v-tooltip>
                      
                    </template>
                  </v-data-table>

                </base-material-card>
            </v-tab-item>

            <v-tab-item>
              <base-material-card
                  icon="mdi-clipboard-text"
                  title="Rekap Data Laporan Pekerjaan"
                  class="px-5 py-3"
                >
                  <v-row>
                    <v-col>
                       <v-dialog
                          ref="SelDate"
                          v-model="menuSelDate"
                          :return-value.sync="txtDatein"
                          persistent
                          width="290px"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="txtDatein"
                            label="Pilih Tanggal"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                          <v-date-picker 
                          v-model="txtDatein"
                          >
                          <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menuSelDate = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.SelDate.save(txtDatein)">OK</v-btn>
                          </v-date-picker>
                          
                      </v-dialog>
                      
                    </v-col>
                    <v-col>
                    <v-btn color="blue" @click="feedingReportTable()">Show Data</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" v-on="on">
                            <export-excel
                                :disabled="listLaporansExcel.length <= 0"
                                :data="listLaporansExcel"
                                :name="excelfName"
                                type="xls"
                                :title="excelTitle"
                                >
                                Download Data
                                <v-icon color="green darken-4">mdi-download</v-icon>
                            </export-excel>
                          </v-btn>
                        </template>
                        <span v-if="listLaporansExcel.length > 0">Silakan Download Excel KLIK Disini.</span>
                        <span v-else>Silakan Refresh Data Terlebih dahulu.</span>
                      </v-tooltip>
                      
                    </v-col>
                  </v-row>
                  

                  <v-data-table
                    :headers="headers_report"
                    :items="listLaporansAll"
                    :items-per-page="15"
                    class="elevation-1"
                  >
                
                  </v-data-table>

                </base-material-card>
            </v-tab-item>

          </v-tabs-items>

        </base-material-tabs>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

    <v-dialog v-model="delConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin meng-HAPUS data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="delConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="doDeleteLaporan()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment'
  import { deleteLaporanWFH, getListByMonth, getListByMonthAllPegawai } from '@/api/laporanwfh'
  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'LaporanDashboard',

    data: () => ({
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'Data Laporan Pekerjaan',
          icon: 'mdi-information',
        }
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'NIK', value: 'UserNIK', align: 'center', class:'subtitle-1' },
        { text: 'Tanggal', value: 'dateIN', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Jabatan', value: 'userpos', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Laporan', value: 'jobTask', align: 'center', class:'subtitle-1' },
        { text: 'Catatan', value: 'notes', align: 'center', class:'subtitle-1' },
        { text: 'Aksi', value: 'aksi', align: 'center', class:'subtitle-1'}
      ],
      headers_report: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'NIK', value: 'UserNIK', align: 'center', class:'subtitle-1' },
        { text: 'Nama', value: 'fullname', align: 'center', class:'subtitle-1' },
        { text: 'Tanggal', value: 'dateIN', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Jabatan', value: 'userpos', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Laporan', value: 'jobTask', align: 'center', class:'subtitle-1' },
        { text: 'Catatan', value: 'notes', align: 'center', class:'subtitle-1' },
      ],
      isLoading: false,
      selectedMonth: new Date().toISOString().substr(0, 7),
      menuSelMonth: false,
      listLaporans: [],
      listLaporansAll: [],
      listLaporansExcel: [],
      delConfirm: false,
      idDel: 0,
      menuSelDate: false,
      txtDatein: moment().format('YYYY-MM-DD'),
      excelfName: 'LaporanWFH_' + moment().format()+ '.xls',
      excelTitle: ''

    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
      let sUser = localStorage.getItem('user')
      vm.dataUser = JSON.parse(sUser)
      vm.isAdmin = false
      if (vm.dataUser !== null) {
        // console.log(vm.dataUser)
        if(vm.dataUser.role !== null) {
          if(vm.dataUser.role.id === 1) {
            vm.isAdmin = true
          }
        }
      }

      if (vm.isAdmin) {
        vm.tabs = [
          {
          text: 'Data Laporan Pekerjaan',
          icon: 'mdi-information',
          },
          {
            text: 'Rekap Laporan Pekerjaan Seluruh Pegawai',
            icon: 'mdi-information',
          }
        ]
      }

    },
    destroyed () {
    },
    mounted () {
      const vm = this
      vm.feedingTable()
    },
    methods: {
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      showAddForm () {
        const vm = this
        vm.$router.push('/repwork/crwfh')
      },
      Get_UserPosAll () {
        const vm = this
        vm.isLoading = true
        vm.dataPos = []
        getUPosAll().then(response => {
          var data = response.data
          // console.log(data)
          vm.dataPos = data
          vm.isLoading = false
          vm.feedingTable()
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
      },
      feedingTable () {
        const vm = this
        var user = vm.dataUser
        var month = moment(vm.selectedMonth).format('YYYY-MM')
        vm.isLoading = true
        getListByMonth(user.nik, month).then(response => {
          var data = response.data
          if (data.length > 0) {
            data.forEach(element => {
              element['userpos'] = element.prefix + element.pos_name
              
              if (element.url !== null){
                element['linkfile'] = _GLOBAL.URLBACKEND + element.url
              }
              else{
                element['linkfile'] = null
              }

              if (element.notes === null || element.notes === ''){
                element['notes'] = '-'
              }

              if (moment(element.dateIN).isValid()){
                element['dateIN'] = moment(element.dateIN).format('DD MMM YYYY')
              }else{
                element['dateIN'] = '-'
              }
            })
          }
          vm.listLaporans = data
          console.log('listLaporans', vm.listLaporans)
          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
      },
      delLaporan (id) {
        const vm = this
        vm.idDel = id
        vm.delConfirm = true
      },
      doDeleteLaporan () {
        const vm = this
        vm.delConfirm = false
        if (vm.idDel == 0) {
          return false
        }
        vm.isLoading = true
        // console.log('deleteLaporanWFH')
        deleteLaporanWFH(vm.idDel).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false
          vm.feedingTable()
          vm.toast = {
            show: true, color:'green', text: 'Laporan berhasil terhapus.', timeout: 2000
          }
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
           vm.toast = {
            show: true, color:'red', text: 'Laporan GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
          }
        })
      },
      feedingReportTable () {
          const vm = this
        var user = vm.dataUser
        var month = moment(vm.txtDatein).format('YYYY-MM-DD')
        var smonth = moment(vm.txtDatein).format('DD MMM YYYY')
        vm.excelTitle = 'DATA LAPORAN PEKERJAAN WFH ' + smonth
        vm.isLoading = true
        getListByMonthAllPegawai(month).then(response => {
          // console.log('getListByMonthAllPegawai', response.data)
          var data = response.data
          if (data.length > 0) {
            var excel = []
            data.forEach(element => {
              element['userpos'] = element.prefix + element.pos_name
              
              if (element.url !== null){
                element['linkfile'] = _GLOBAL.URLBACKEND + element.url
              }
              else{
                element['linkfile'] = null
              }

              if (element.notes === null || element.notes === ''){
                element['notes'] = '-'
              }

              if (moment(element.dateIN).isValid()){
                element['dateIN'] = moment(element.dateIN).format('DD MMM YYYY')
              }else{
                element['dateIN'] = '-'
              }
              var tmp = {
                'Tanggal': element['dateIN'], 'NIK': element['UserNIK'], 'Nama': element['fullname'], 'Laporan Pekerjaan': element['jobTask'], 'Catatan': element['notes'], 'Jabatan': element['userpos'], 'LinkFile': element['linkfile']
              }
              excel.push(tmp)
            })
            vm.listLaporansExcel = excel
            console.log('listLaporansExcel', vm.listLaporansExcel)
          }
          vm.listLaporansAll = data
          console.log('listLaporansAll', vm.listLaporansAll)
          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
      }
    }
  }
</script>
