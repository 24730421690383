import request from '../utils/request'
import { _GLOBAL } from './server'


export function getListByMonthAllPegawai (dateIN) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs/f/bymonth?dateQ=' + dateIN 
  // console.log(uri)
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListByMonth (UserNIK, dateIN) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs/f/bynikmonth?UserNIK=' + UserNIK + '&dateQ=' + dateIN + '&_sort=id%3ADESC'
  // console.log(uri)
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getUserPosByUserID (id) {
  // console.log('on api getLaporanByID')
  // console.log(id)
  if (id <= 0) return null

  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/hruserpos/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getUserPosByID (id) {
  // console.log('on api getLaporanByID')
  // console.log(id)
  if (id <= 0) return null

  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getUPosAll () {
  // console.log('on api getUserPosAll')

  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/hrpositions'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getLaporanByID (id) {
  // console.log('on api getLaporanByID')
  // console.log(id)
  if (id <= 0) return null

  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertLaporanWFH (data) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteLaporanWFH (id) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateLaporanWFH (data, id) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countLaporanWFH () {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs/count'
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function uploadFileAttachment (refID, file) {
  // console.log('uploadFileAttachment')
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  const bodyFormData = new FormData()
  bodyFormData.append('refId', refID)
  bodyFormData.append('field', 'attachment')
  bodyFormData.append('ref', 'laporanWFH')
  // bodyFormData.append('source', 'laporanWFH')
  bodyFormData.append('path', 'laporanWFH/attachment')
  // Add each file upload to the form data
  bodyFormData.append('files', file, file.name)
  // console.log(file.name)
  var uri = _GLOBAL.URLBACKEND + '/upload'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: bodyFormData,
    contentType: false,
    processData: false,
    crossDomain: true,
    async: true,
    mimeType: 'multipart/form-data'

  })
  // console.log(response)
  return response
}

export function getdataforReport (dateQ) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/laporanwfhs/f/bymonth?dateQ=' + dateQ
  // console.log(uri)
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}
